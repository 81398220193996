import { Card, CardBody, Heading, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ConsularModel } from "../../../models/consularModel";

interface ConsularCardProps{consularData: ConsularModel}

const ConsularCard: React.FC<ConsularCardProps> = (props: ConsularCardProps) => {

    const navigate = useNavigate();

    return(
        <>
            <Card margin={2} onClick={() => navigate(`/consular-service-view/${props.consularData.id}`)}>
                <CardBody>
                    <Heading size='md'>{props.consularData.title}</Heading>

                    <Spacer height={1}/>

                    <Text fontSize={12}>Last Updated : {props.consularData.lastUpdatedDate}</Text>
                </CardBody>
            </Card>
        </>
    );
}

export default ConsularCard;