import { Card, Collapse, Heading, Stack, useDisclosure } from "@chakra-ui/react";
import { DocumentData, getDocs, orderBy, query, QuerySnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import Colors from "../../../constaint/Colors";
import { AnnouncementModel } from "../../../models/announcementModel";
import { announcementsCollection } from "../../../services/AnnouncementService";
import NoDataLable from "../../reusable-components/NoDataLable";
import AnnouncementCard from "./Announcement-Card";

const Announcements: React.FC = () => {

    const [announcements, setAnnouncements] = useState<AnnouncementModel[]>([]);
    const { isOpen, onToggle } = useDisclosure();
    
    // eslint-disable-next-line
    useEffect(() => getAnnouncementsList(),[]);

    const getAnnouncementsList = () => {
        getDocs(
            query(
                announcementsCollection,
                orderBy('index', 'asc')
            ), 
        ).then((snapshot: QuerySnapshot<DocumentData>)=>{
            setAnnouncements(
                snapshot.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data(),
                    };
                })
            );
            onToggle();
        });
    }

    return(
        <Collapse in={isOpen} animateOpacity>
            {
                announcements.length > 0 
                ?   <Card
                        margin={2}
                        padding={2}
                        border='2px'
                        borderColor={Colors.kGreen}
                    >
                        <Heading margin={3} color={Colors.kGreen}>{announcements.length > 1 ? 'Announcements 📢' : 'Announcement 📢'}</Heading>

                        <Stack>
                            {
                                announcements && announcements.length 
                                ? (
                                    <div>
                                        {announcements?.map((announcement)=>(
                                            <AnnouncementCard key={announcement.id} announcementData={announcement}/>
                                        ))}
                                    </div>
                                ) 
                                : (
                                    <NoDataLable title="No Announcements" />
                                )
                            }
                        </Stack>
                    </Card>
                : <></>
            }
        </Collapse>
        
    );
}

export default Announcements;