import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { firebaseAuth, firebaseFirestore } from "./FirebaseService";
import Cookies from 'universal-cookie';
import { sendNewAccountMail } from "./EmailService";

export const adminCollection = collection(firebaseFirestore, 'admin');

export const loginUser = async (email: string, password: string) => {

    const uid = (await signInWithEmailAndPassword(firebaseAuth, email, password)).user.uid;

    const document = doc(firebaseFirestore, `users/${uid}`);
    const userData = (await getDoc(document)).data();

    if(userData){
        const cookies = new Cookies();
        cookies.set('uid', uid);
        return true;
    }
    
    return false;
}

export const createUser = async (email: string, password: string, userData: any) => {

    const uid = (await createUserWithEmailAndPassword(firebaseAuth, email, password)).user.uid;

    const document = doc(firebaseFirestore, `users/${uid}`);
    await setDoc(document, userData).then(()=>sendNewAccountMail(email));

}

export const isUserLogged = () => {
    const cookies = new Cookies();
    return cookies.get('uid') !== undefined;
}

export const getUserID = () => {
    const cookies = new Cookies();
    return cookies.get('uid');
}

export const getUserEmail = () => {
    return firebaseAuth.currentUser?.email;
}

export const sendResetPasswordEmail = async (email: string) => {
    await sendPasswordResetEmail(firebaseAuth, email);
}

export const getLogout = () => {
    const cookies = new Cookies();
    cookies.remove('uid');
    firebaseAuth.signOut();
}