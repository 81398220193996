import { Card, CardBody, Heading, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { QuestionModel } from "../../../models/questionModel";

interface QuestionCardProps{questionData: QuestionModel}

const QuestionCard: React.FC<QuestionCardProps> = (props: QuestionCardProps) => {

    const navigate = useNavigate();

    return(
        <>
            <Card margin={2} onClick={() => navigate(`/question-and-answer-view/${props.questionData.id}`)}>
                <CardBody>
                    <Heading size='md'>{props.questionData.question}</Heading>

                    <Spacer height={1}/>

                    <Text fontSize={12}>Last Updated : {props.questionData.lastUpdatedDate}</Text>
                </CardBody>
            </Card>
        </>
    );
}

export default QuestionCard;