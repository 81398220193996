
export const EncryptString = (input: string): string => {
    const list = new TextEncoder().encode(input);
  
    const newList: number[] = [];
  
    for (let index = 0; index < list.length; index++) {
      if (list[index] % 2 === 0) {
        newList.push(list[index] - 18);
        newList.push(list[index] - 3);
      } else {
        newList.push(list[index] - 6);
        newList.push(list[index] - 29);
      }
    }
  
    return new TextDecoder().decode(new Uint8Array(newList.reverse()));
}

export const DecryptString = (input: string): string => {
    const list = new TextEncoder().encode(input);

    const newList: number[] = [];

    for (let index = 0; index < list.length; index++) {
        if (index % 2 !== 0) {
        if (list[index] % 2 === 0) {
            newList.push(list[index] + 18);
        } else {
            newList.push(list[index] + 6);
        }
        }
    }

    return new TextDecoder().decode(new Uint8Array(newList.reverse()));
}