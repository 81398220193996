import { ChakraProvider, theme } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppBar from "./components/reusable-components/AppBar";
import AppointmentsScreen from "./components/screens/Appointments/Appointments-Screen";
import ChatScreen from "./components/screens/Chats/Chat-Screen";
import ConsularServicesScreen from "./components/screens/ConsularServices/Consular-Services-Scree";
import ConsularServiceView from "./components/screens/ConsularServices/Consular-Service-View";
import ContactDetailsScreen from "./components/screens/ContactDetails/Contact-Details-Screen";
import CreateAccountScreen from "./components/screens/Create-Account-Screen";
import HomeScreen from "./components/screens/Home/Home-Screen";
import LoginScreen from "./components/screens/Login-Screen";
import NotFoundScreen from "./components/screens/Not-Found-Screen";
import QuestionsScreen from "./components/screens/Questions/Questions-Screen";
import QuestionView from "./components/screens/Questions/Question-View";

export const App = () => (
  <ChakraProvider theme={theme}>
    <BrowserRouter>

      <AppBar />

      <Routes>
        <Route path="/" element={<HomeScreen />} />

        <Route path="/login" element={<LoginScreen />} />
        <Route path="/create-account" element={<CreateAccountScreen />} />

        <Route path="/appointments" element={<AppointmentsScreen />} />
        <Route path="/chat" element={<ChatScreen />} />

        <Route path="/consular-services" element={<ConsularServicesScreen />} />
        <Route path="/consular-service-view/:id" element={<ConsularServiceView />} />

        <Route path="/question-and-answers" element={<QuestionsScreen />} />
        <Route path="/question-and-answer-view/:id" element={<QuestionView />} />

        <Route path="/contact-details" element={<ContactDetailsScreen />} />

        <Route path="*" element={<NotFoundScreen />} />
      </Routes>

      {/* <Footer /> */}
    </BrowserRouter>
  </ChakraProvider>
)
