export const kMaxAppointmentsCountPerDay = 40;

export const kTimeSlot: { [key: string]: string }[] = [
    {
      '1': '9:00 AM',
      '2': '9:00 AM',
      '3': '9:00 AM',
      '4': '9:00 AM',
      '5': '9:00 AM',
      '6': '9:00 AM',
      '7': '9:00 AM',
      '8': '9:00 AM',
      '9': '9:00 AM',
      '10': '9:00 AM',
      '11': '10:00 AM',
      '12': '10:00 AM',
      '13': '10:00 AM',
      '14': '10:00 AM',
      '15': '10:00 AM',
      '16': '10:00 AM',
      '17': '10:00 AM',
      '18': '10:00 AM',
      '19': '10:00 AM',
      '20': '10:00 AM',
      '21': '11:00 AM',
      '22': '11:00 AM',
      '23': '11:00 AM',
      '24': '11:00 AM',
      '25': '11:00 AM',
      '26': '11:00 AM',
      '27': '11:00 AM',
      '28': '11:00 AM',
      '29': '11:00 AM',
      '30': '11:00 AM',
      '31': '12:00 PM',
      '32': '12:00 PM',
      '33': '12:00 PM',
      '34': '12:00 PM',
      '35': '12:00 PM',
      '36': '12:00 PM',
      '37': '12:00 PM',
      '38': '12:00 PM',
      '39': '12:00 PM',
      '40': '12:00 PM',
    },
  ];
  