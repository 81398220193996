class Colors{
    static kGreen = '#5DBB63';
    static kGreen1 = '#d1e8d2';
    static kGreen2 = '#b4d4b5';
    static kGreen3 = '#93ba94';
    static kGreen4 = '#79a87a';
    static kGreen5 = '#69d16f';
    

    static kChatGreen = '#06730d';
    static kChatGrey = '#333333';
}

export default Colors;