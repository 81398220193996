import { addDoc, collection } from "firebase/firestore";
import { kTimeSlot } from "../constaint/TimeSlots";
import { getUserEmail } from "./AuthService";
import { firebaseFirestore } from "./FirebaseService";

export const emailCollection = collection(firebaseFirestore, 'mail');

export const sendNewAppointmentMail = async (date: string, appointmentNumber: number) => {

    await addDoc(emailCollection, {
        to: getUserEmail(),
        message: {
            subject: 'Appointment Confirmation',
            html: `Your Appointment Details, \n\n Date: ${date} \n Number: ${appointmentNumber} \n Time: ${kTimeSlot[0][appointmentNumber]} \n\nThank You!`
        }
    });

}

export const sendNewAccountMail = async (email: string) => {

    await addDoc(emailCollection, {
        to: email,
        message: {
            subject: 'Account Confirmation',
            html: `Hello, \n\n You have successfully created your conFacile Account using ${email}. \n\nThank You!`
        }
    });

}