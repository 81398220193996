import { SimpleGrid } from "@chakra-ui/react";
import React from "react";
import SingleButtonCard from "./Single-Button-Card";

const ButtonCards: React.FC = () => {
    return(
        <SimpleGrid 
            columns={[1, 2, 4]} 
            spacing='10px'
            margin={3}
        >

            <SingleButtonCard 
                title="Contact via Chat"
                description="Contact the Embassy directly via Chat"
                animation="chat"
                route="/chat"
                rightSide={false}
            />

            <SingleButtonCard 
                title="Appointments"
                description="Make Appointments to visit the Embassy of Sri Lanka in Italy"
                animation="appointments"
                route="/appoinments"
                rightSide={true}
            />

            <SingleButtonCard 
                title="Consular Services"
                description="Consular Services we provide and details about all of them"
                animation="consular"
                route="/consular-services"
                rightSide={false}
            />

            <SingleButtonCard 
                title="Question & Answers"
                description="Frequently asked Questions and Answers"
                animation="qna"
                route="/question-and-answers"
                rightSide={true}
            />

        </SimpleGrid>
    );
}

export default ButtonCards;