import { ReactNode, useEffect, useState } from 'react';
import { Box, Flex, Avatar, HStack, Text, Link, IconButton, Button, Menu, MenuButton, MenuList, MenuItem, MenuDivider, useDisclosure, useColorModeValue, Stack, Card, Heading, Spacer } from '@chakra-ui/react';
import { MdClose, MdReorder } from 'react-icons/md';
import { ColorModeSwitcher } from '../../ColorModeSwitcher';
import Colors from '../../constaint/Colors';
import { useNavigate } from 'react-router-dom';
import { getLogout, getUserID, isUserLogged } from '../../services/AuthService';
import { UserModel } from '../../models/userModel';
import { getUserDetails } from '../../services/UserService';

const Links = ['Home', 'Consular Services', 'Q & A', 'Contact Details'];


interface NavLinkProps{
  children: ReactNode,
  callBack: any,
}

const NavLink = (props: NavLinkProps) => {
  return(
    <Link
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.700', 'gray.700'),
      }}
      color='white'
      fontWeight='semibold'
      onClick={props.callBack}
    >
      {props.children}
  </Link>
  );
}

const AppBar:React.FC = () => {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserModel>();

  useEffect(
    ()=>{
      getUserData();
    },[navigate]
  );
  
  const getUserData = async () =>{
    setUserData((await getUserDetails(getUserID())).data());
  }

  const changePage = (link: any) => {

    if(link === 'Home'){
      navigate('/');
    }else if(link === 'Consular Services'){
      navigate('/consular-services');
    }else if(link === 'Q & A'){
      navigate('/question-and-answers');
    }else{
      navigate('/contact-details');
    }
  }

  return (
    <>
      <Box bg={useColorModeValue(Colors.kGreen, 'gray.900')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'sm'}
            icon={isOpen ? <MdClose size='lg' color='white'/> : <MdReorder size='lg' color='white'/>}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
            backgroundColor={useColorModeValue(Colors.kGreen, 'gray.900')}
          />
          <HStack spacing={8} alignItems={'center'}>

            <Box>
                <Text onClick={()=>navigate('/')} fontWeight='extrabold' fontSize={20} fontFamily={'RammettoOne'} color='white'>conFacile</Text>
            </Box>

            <HStack
              as={'nav'}
              spacing={4}
              display={{ base: 'none', md: 'flex' }}>
              {Links.map((link) => (
                <NavLink key={link} callBack={()=>changePage(link)}>{link}</NavLink>
              ))}
            </HStack>
          </HStack>
          <Flex alignItems={'center'}>
            <ColorModeSwitcher margin={3} color='white'/>

            {
              isUserLogged()
              ? (
                  <Menu>
                    <MenuButton
                      as={Button}
                      rounded={'full'}
                      variant={'link'}
                      cursor={'pointer'}
                      minW={0}>
                      <Avatar
                        size={'sm'}
                        src={'https://www.google.com/url?sa=i&url=https%3A%2F%2Fpixabay.com%2Fvectors%2Fblank-profile-picture-mystery-man-973460%2F&psig=AOvVaw3e3XWxC7zztRoxzw6fWqbW&ust=1678381481193000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCLiJ3LHozP0CFQAAAAAdAAAAABAE'}
                        //background={useColorModeValue(Colors.kGreen, 'gray.900')}
                      />
                    </MenuButton>
                
                    <MenuList >
      
                      <MenuItem onClick={()=>navigate('/appointments')}>Appointments</MenuItem>
                      <MenuItem onClick={()=>navigate('/chat')}>Chat</MenuItem>
      
                      <MenuDivider />

                      <Card 
                        margin={2} 
                        padding={1}  
                        paddingTop={3}
                        alignItems='center' 
                        maxWidth={250}
                      >

                        <Heading size='lg'>{userData?.passportNumber ?? 'Loading...'}</Heading>
                        <Heading size='sm' textAlign='center'>{userData?.name ?? 'Loading...'}</Heading>

                        
                        <Box height={5} />
                        
                        <Text>{userData?.email ?? 'Loading...'}</Text>
                        <Text>{userData?.mobile ?? 'Loading...'}</Text>

                      </Card>

                      <MenuDivider />
      
                      <MenuItem onClick={()=>{getLogout(); navigate('/login');}}>Logout</MenuItem>
      
                    </MenuList>
                  </Menu>
                )
              : (
                  <Button onClick={()=>navigate('/login')} color={Colors.kGreen}>LOGIN</Button>
                )
            }

          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link} callBack={()=>changePage(link)}>{link}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>

      {/* <Box p={4}>Main Content Here</Box> */}
    </>
  );
}

export default AppBar;