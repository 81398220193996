import { Spinner } from "@chakra-ui/react";
import React from "react";

interface LoadingIndicatorPros{
    size?: number;
    height?: number;
}

const LoadingIndicator: React.FC<LoadingIndicatorPros> = (props: LoadingIndicatorPros) => {
    return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: `${props.height ?? 50}vh`}}>
            <Spinner size='xl' width={props.size ?? 150} height={props.size ?? 150}/>
        </div>
    );
}

export default LoadingIndicator;