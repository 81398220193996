import { Box, Button, Card, Flex, Heading, Input, Spacer, Stack, useToast, useColorModeValue, FormControl, FormLabel, Text, HStack, Hide, Show } from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Colors from "../../constaint/Colors";
import { privacyPolicyURL } from "../../constaint/Values";
import { createUser } from "../../services/AuthService";
import { showErrorToast, showSuccessToast, showWarningToast } from "../../services/ToastServise";

const CreateAccountScreen : React.FC = () => {

    const [isLoading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [passportNumber, setpassportNumber] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const toast = useToast();
    const navigate = useNavigate();

    const validateForm = () => {

        if(name === ''){
            showWarningToast(
              toast,
              'Name is Missing',
              'You cannot Create Account without a Name',
            );
    
            return;
        }

        if(passportNumber === ''){
          showWarningToast(
            toast,
            'Passport Number is Missing',
            'You cannot Create Account without a Passport Number',
          );
  
          return;
        }

        if(passportNumber.length !== 8){
          showWarningToast(
            toast,
            'Oops!',
            'Looks like Passport Number is wrong',
          );
  
          return;
        }

        if(mobile === ''){
          showWarningToast(
            toast,
            'Mobile Number is Missing',
            'You cannot Create Account without a Mobile Number',
          );
  
          return;
        }

        if(mobile.length < 10){
          showWarningToast(
            toast,
            'Oops!',
            'Looks like Mobile Number is wrong!',
          );
  
          return;
        }

        if(email === ''){
          showWarningToast(
            toast,
            'Email is Missing',
            'You cannot Create Account without a Email',
          );
  
          return;
        }

        if(password === ''){
            showWarningToast(
              toast,
              'Password is Missing',
              'You cannot Create Account without a Password',
            );
    
            return;
        }

        if(password.length < 6){
          showWarningToast(
            toast,
            'Password is too short',
            'Your Password must contain more than 6 characters!',
          );
  
          return;
        } 

        if(password !== confirmPassword){
          showWarningToast(
            toast,
            'Passwords not Matching!',
            'You cannot Create Account without matching Password and Confirm Password',
          );
  
          return;
        }


      // call Create Account function
      createAccount();
    }


    const createAccount = async () => {

        setLoading(true);

        await createUser(
            email, 
            password,
            {
              name: name,
              email: email,
              passportNumber: passportNumber,
              mobile: mobile,
            }
          ).then(()=>{

              navigate('/appointments');

              showSuccessToast(
                toast,
                'Welcome!',
                'Account Create Successful!',
              );

          }).catch((error)=>{

              showErrorToast(
                toast,
                'Account Create Failed!',
                'You cannot Create Account at the moment!',
              );

          });

        setLoading(false);
    }

    const goToPrivacyPolicy = () => {
        window.open(privacyPolicyURL);
    }

    return (
        <Flex
          minH={'75vh'}
          align={'center'}
          justify={'center'}
          bg={useColorModeValue('gray.50', 'gray.800')}>
          <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
            <Stack align={'center'}>
              <Heading fontSize={'4xl'}>Create Account</Heading>
            </Stack>
            <Box
              rounded={'lg'}
              bg={useColorModeValue('white', 'gray.700')}
              boxShadow={'lg'}
              p={8}
              minWidth={350}
            >
              <Stack spacing={4}>

                <FormControl id="name">
                  <FormLabel>Name</FormLabel>
                  <Input type="text" onChange={(text)=>setName(text.target.value)}/>
                </FormControl>

                <HStack>
                    <FormControl id="passport">
                        <FormLabel>Passport Number</FormLabel>
                        <Input type="text" onChange={(text)=>setpassportNumber(text.target.value)}/>
                    </FormControl>

                    <FormControl id="mobile">
                        <FormLabel>Mobile</FormLabel>
                        <Input type="number" onChange={(text)=>setMobile(text.target.value)}/>
                    </FormControl>
                
                </HStack>

                <FormControl id="email">
                  <FormLabel>Email</FormLabel>
                  <Input type="email" onChange={(text)=>setEmail(text.target.value)}/>
                </FormControl>

                <HStack>
                    <FormControl id="password">
                        <FormLabel>Password</FormLabel>
                        <Input type="password" onChange={(text)=>setPassword(text.target.value)}/>
                    </FormControl>

                    <FormControl id="confirmPassword">
                        <FormLabel>Re-Password</FormLabel>
                        <Input type="password" onChange={(text)=>setConfirmPassword(text.target.value)}/>
                    </FormControl>
                </HStack>

                <>
                    <Show above='md'>
                        <HStack>
                            <Text align='center'>By creating account, You agree to </Text>
                            <Button onClick={goToPrivacyPolicy} padding={0} bg='transparent' size='sm' color={'blue.400'}>Privacy Policy</Button>
                        </HStack>
                    </Show>

                    <Show below='md'>
                        <Text align='center'>By creating account, You agree to <Button onClick={goToPrivacyPolicy} bg='transparent' size='sm' color={'blue.400'}>Privacy Policy</Button></Text>            
                    </Show>
                </>

                <Button
                    bg={Colors.kGreen}
                    color={'white'}
                    onClick={validateForm}
                    isLoading={isLoading}
                    _hover={{
                      bg: Colors.kGreen5,
                    }}>
                    Create Account
                  </Button>

                <Text align='center'>or</Text>

                <Button onClick={()=>navigate('/login')} bg='transparent'>Go to Login</Button>

              </Stack>
            </Box>
          </Stack>
        </Flex>
      );
}

export default CreateAccountScreen;