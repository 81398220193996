import { Card, CardBody, Divider, Heading, HStack, Text } from "@chakra-ui/react";
import { MdInfoOutline } from "react-icons/md";
import Colors from "../../../constaint/Colors";
import { AnnouncementModel } from "../../../models/announcementModel";

interface AnnouncementCardProps{announcementData: AnnouncementModel}

const AnnouncementCard: React.FC<AnnouncementCardProps> = (props: AnnouncementCardProps) => {

    return(
        <>
            <Card margin={3} bg={props.announcementData.isImportant ? 'tomato' : Colors.kGreen}>
                <CardBody>

                    <Text fontSize='12' color='whiteAlpha.500'>Announcement</Text>

                    <Divider/>

                    <HStack margin={1}>
                        <MdInfoOutline size={20} color='white'/>
                        <Heading size='md' color='white'>{props.announcementData.title}</Heading>
                    </HStack>

                    <Text minHeight={2} color='white'>{props.announcementData.description}</Text>

                </CardBody>
            </Card> 
        </>
    );
}

export default AnnouncementCard;