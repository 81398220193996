import React, { useEffect, useState } from "react";
import { ConsularModel } from "../../../models/consularModel";
import { useParams } from 'react-router';
import { Card, CardBody, Heading, useColorMode, Text, Spacer, UnorderedList, ListItem } from "@chakra-ui/react";
import { getConsularServiceDataFromID } from "../../../services/ConsularService";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import LoadingIndicator from "../../reusable-components/LoadingIndicator";

const ConsularServiceView: React.FC = () => {

    const { id } = useParams();
    const [consularData, setConsularData] = useState<ConsularModel>();
    const [isLoading, setLoading] = useState(true);
    const { colorMode } = useColorMode();

    useEffect(()=>{
        getQuestionData();
    },[]);

    const getQuestionData = async () => {

        await getConsularServiceDataFromID(id!).then((snapshot: DocumentSnapshot<DocumentData>)=>{
            setConsularData(
                {
                    id: snapshot.id,
                    ...snapshot.data(),
                }
            );

            setLoading(false);
        });
    }

    function followLink() {
        window.open(`${consularData?.applicationURL}`);
    }



    return(
        <>
            {
                isLoading
                ? <LoadingIndicator />
                : (
                    <Card margin={2}>

                        <CardBody>
                            <Heading size='md'>{consularData?.title}</Heading>

                            <Text minHeight={2}>{consularData?.description}</Text>

                            {
                                consularData?.application !== ''
                                ?   <Card margin={1} border='1px'>
                                        <CardBody>
                                            <Text as='b'>{consularData?.application}</Text>

                                            <Text
                                                color={colorMode === 'light' ? 'blue' : 'lightblue'}
                                                onClick={followLink}
                                            >
                                                {consularData?.applicationURL}
                                            </Text>
                                        </CardBody>
                                    </Card>
                                : <Spacer />
                            }

                            {
                                consularData!.documents!.length > 0 
                                ?   <UnorderedList>
                                        {consularData?.documents?.map(doc => <ListItem key={doc}>{doc}</ListItem>)}
                                    </UnorderedList>
                                : <Spacer />
                            }

                        </CardBody>
                    </Card>
                )
            }

        </>
    );
}

export default ConsularServiceView;