import { Stack } from "@chakra-ui/react";
import { DocumentData, getDocs, query, QuerySnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { listHeight } from "../../../constaint/Values";
import { ContactModel } from "../../../models/contactModel";
import { contactCollection } from "../../../services/ContactService";
import LoadingIndicator from "../../reusable-components/LoadingIndicator";
import NoDataLable from "../../reusable-components/NoDataLable";
import ContactCard from "./Contact-Card";

const ContactDetailsScreen: React.FC = () => {

    const [isLoading, setLoading] = useState(true);
    const [contacts, setContacts] = useState<ContactModel[]>([]);

    useEffect(() => getContactDetailsList(),[]);

    const getContactDetailsList = () =>{
        setLoading(true);
        getDocs(
            query(
                contactCollection,
            ), 
        ).then((snapshot: QuerySnapshot<DocumentData>)=>{
            setContacts(
                snapshot.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data(),
                    };
                })
            );
            setLoading(false);
        });
    }
    
    return(
        <>

            <Stack overflowY='auto' height={listHeight}>
                {
                    !isLoading 
                    ? (
                        contacts && contacts.length 
                        ? (
                            <div>
                                {contacts?.map((contact)=>(
                                    <ContactCard key={contact.id} contactData={contact}/>
                                ))}
                            </div>
                        ) 
                        : (
                            <NoDataLable title="No Contacts" />
                        )
                    ) 
                    : (
                        <LoadingIndicator />
                    )
                }
            </Stack>

        </>
    );
}

export default ContactDetailsScreen;