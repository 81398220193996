import { Card, CardBody, HStack, Spacer, Text, Image } from "@chakra-ui/react";
import React from "react";
import { MessageModel } from "../../../models/messageModel";
import { DecryptString } from "../../../services/EncryptionService";

interface MessageCardProps{
    id: string;
    messageData: MessageModel;
}

const MessageCard: React.FC<MessageCardProps> = (props: MessageCardProps) => {

    const viewImage = () => {
        window.open(`${props.messageData.content}`);
    }

    return(
        <>
            <HStack margin={2}>

                { props.id === props.messageData.from ? <Spacer /> : <></>}

                <Card onClick={viewImage}>
                    <CardBody padding={2}>

                        {
                            props.messageData.type === 'text'
                            ?   <Text>{DecryptString(props.messageData.content!)}</Text>
                            :   <Image 
                                    maxWidth={300}
                                    minWidth={100}
                                    minHeight={100}
                                    src={props.messageData.content} 
                                    alt='Image' 
                                    loading='lazy'
                                />
                        }

                    </CardBody>
                </Card>

                { props.id !== props.messageData.from ? <Spacer /> : <></>}

            </HStack>
        </>
    );
}

export default MessageCard;