import { Box, Button, Card, Flex, FormControl, FormLabel, Heading,Text, Input, Spacer, Stack, useColorModeValue, useToast, HStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Colors from "../../constaint/Colors";
import { loginUser, sendResetPasswordEmail } from "../../services/AuthService";
import { showErrorToast, showSuccessToast, showWarningToast } from "../../services/ToastServise";

const LoginScreen : React.FC = () => {

    const [isLoading, setLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const toast = useToast();
    const navigate = useNavigate();

    const login = async () => {
        if(email === ''){
            showWarningToast(
              toast,
              'Email is Missing',
              'You cannot login without Email',
            );
    
            return;
        }

        if(password === ''){
            showWarningToast(
              toast,
              'Password is Missing',
              'You cannot login without Password',
            );
    
            return;
        }

        setLoading(true);

        const isLogged = await loginUser(email, password).catch((error)=>{console.log(error)});

        setLoading(false);

        if(isLogged){
            navigate('/appointments');

            showSuccessToast(
                toast,
                'Welcome!',
                'Login Successful!',
              );
      
            return;
        } else{
            showErrorToast(
                toast,
                'Login Failed!',
                'You cannot login!',
              );
      
            return;
        }
    }

    const resetPassword = () => {
        if(email === ''){
            showWarningToast(
              toast,
              'Email is Missing',
              'You cannot Reset Password without Email',
            );
    
            return;
        }

        sendResetPasswordEmail(email).then(()=>{
          showSuccessToast(
            toast,
            'Successful!',
            'Password Reset Email sent Successful!',
          );
        }).catch((error)=>{
          showErrorToast(
            toast,
            'Oops!',
            'Cannot send Password Reset Email at the moment!',
          );
        });

    }

    return (
        <Flex
          minH={'75vh'}
          align={'center'}
          justify={'center'}
          bg={useColorModeValue('gray.50', 'gray.800')}>
          <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
            <Stack align={'center'}>
              <Heading fontSize={'4xl'} >LOGIN</Heading>
            </Stack>
            <Box
              rounded={'lg'}
              bg={useColorModeValue('white', 'gray.700')}
              boxShadow={'lg'}
              p={8}
              minWidth={350}
            >
              <Stack spacing={4}>
                <FormControl id="email">
                  <FormLabel>Email</FormLabel>
                  <Input type="email" onChange={(text)=>setEmail(text.target.value)}/>
                </FormControl>
                <FormControl id="password">
                  <FormLabel>Password</FormLabel>
                  <Input type="password" onChange={(text)=>setPassword(text.target.value)}/>
                </FormControl>
                
                <Button
                    bg={Colors.kGreen}
                    color={'white'}
                    onClick={login}
                    isLoading={isLoading}
                    _hover={{
                      bg: Colors.kGreen5,
                    }}>
                    LOGIN
                  </Button>

                <Button size='sm' bg='transparent' color={'blue.400'} onClick={resetPassword}>Forgot password?</Button>

                <Text align='center'>or</Text>

                <Button onClick={()=>navigate('/create-account')} bg='transparent'>Create Account</Button>

              </Stack>
            </Box>
          </Stack>
        </Flex>
      );
    
}

export default LoginScreen;