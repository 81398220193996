import { Card, Heading, Spacer } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isHaveAppointment } from "../../../services/AppointmentService";
import { isUserLogged } from "../../../services/AuthService";
import LoadingIndicator from "../../reusable-components/LoadingIndicator";
import NewAppointmentComponent from "./New-Appointment-Component";
import NextAppointmentComponent from "./Next-Appointment-Component";

const AppointmentsScreen: React.FC = () => {

    const navigate = useNavigate();
    const [haveAppoitnment, setHaveAppointmnet] = useState<boolean>();
    const [isLoading, setLoading] = useState(false);

    useEffect(
        ()=>{

            if (!isUserLogged()) {
                navigate('/login');
            }

            getData();

        },[]
    );

    const getData = async () => {
        setLoading(true);

        await isHaveAppointment().then((value)=>{
            setHaveAppointmnet(value);
            setLoading(false);
        });
    }

    return(
        <>
            <Spacer height={30}/>

            <Heading textAlign='center'>APPOINTMENTS</Heading>

            <Spacer height={50}/>

            {
                !isLoading
                ?   (
                        <Card 
                            margin={[5, 'auto', 'auto', 'auto']}
                            maxWidth={400}
                            padding={5}
                        >
                            {
                                haveAppoitnment ? <NextAppointmentComponent/> : <NewAppointmentComponent update={getData}/>
                            }
                        </Card>
                    )
                : <LoadingIndicator/>
            }
        </>
    );
}

export default AppointmentsScreen;