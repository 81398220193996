import { Card, Text, Image, Heading, VStack, Show, HStack } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

interface SingleButtonCardProps {
    title: string;
    description: string;
    animation: string;
    route: string;
    rightSide: boolean;
}

const SingleButtonCard: React.FC<SingleButtonCardProps> = (props: SingleButtonCardProps) => {

    const navigate = useNavigate();

    const changeRoute = () => {
        navigate(props.route);
    }

    return(
        
        <>
            <Show above='md'>
                <Card padding={5} onClick={changeRoute}>
                    <VStack>
                        <Image src={require(`./animations/${props.animation}.gif`)}/>

                        <Heading size='md'>{props.title}</Heading>

                        <Text align='center'>{props.description}</Text>
                    </VStack>
                </Card>
            </Show>

            <Show below='md'>

                <Card padding={5} onClick={changeRoute}>
                    {
                        !props.rightSide
                        ?   <HStack>
                                <Image maxWidth={100} src={require(`./animations/${props.animation}.gif`)}/>

                                <VStack>                 
                                    <Heading size='md'>{props.title}</Heading>
                                    <Text align='center'>{props.description}</Text>
                                </VStack>
                            </HStack>
                        :   <HStack>
                                <VStack>                 
                                    <Heading size='md'>{props.title}</Heading>
                                    <Text align='center'>{props.description}</Text>
                                </VStack>

                                <Image maxWidth={100} src={require(`./animations/${props.animation}.gif`)}/>
                            </HStack>
                    }
                </Card>
    
            </Show>
        </>
    );
}

export default SingleButtonCard;