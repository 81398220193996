import { Input, VStack, Text, Heading, Spacer, Box, Button, useToast } from "@chakra-ui/react";
import { onValue, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import { kMaxAppointmentsCountPerDay, kTimeSlot } from "../../../constaint/TimeSlots";
import { convertDate, createAppointment, getAppointmentNumber, getDateString, getInitialAppointmentDate, getTodayDateString } from "../../../services/AppointmentService";
import LoadingIndicator from "../../reusable-components/LoadingIndicator";
import Colors from "../../../constaint/Colors";
import { firebaseRealTimeDataBase } from "../../../services/FirebaseService";
import { Timestamp } from "firebase/firestore";
import { getUserID } from "../../../services/AuthService";
import { showErrorToast, showSuccessToast } from "../../../services/ToastServise";

interface NewAppointmentComponentProps{update: any}

const NewAppointmentComponent: React.FC<NewAppointmentComponentProps> = (props: NewAppointmentComponentProps) => {

    const [isLoading, setLoading] = useState(false); //set to true
    const [isLoadingMakeAppointment, setLoadingMakeAppointment] = useState(false);
    const [date, setDate] = useState(getTodayDateString());
    const [onGoingAppointmentNumber, setOnGoingAppointmentNumber] = useState(-1);

    const toast = useToast();

    useEffect(()=>{getInitialDate()},[]);

    useEffect(()=>{getOnGoingAppointmentNumber()},[date]);

    const getInitialDate = async () => {

        setLoading(true);

        const tempDate: Date = new Date(date);
        tempDate.setDate(tempDate.getDate() + 1);

        const initialDate: Date = await getInitialAppointmentDate(tempDate);
        setDate(getDateString(initialDate));

        setLoading(false);
    }

    const getOnGoingAppointmentNumber = () => {
        const appointmentsRef = ref(firebaseRealTimeDataBase, `appointments/${convertDate(new Date(date))}`);

        onValue(
            appointmentsRef, (snapshot) => {
                setOnGoingAppointmentNumber(snapshot.val() !== null ? snapshot.val() : 0);
            }
        );
    }

    const makeAppointment = async () => {
        setLoadingMakeAppointment(true);

        const appointmentNumber = await getAppointmentNumber(new Date(date));

        if(appointmentNumber < kMaxAppointmentsCountPerDay){
            await createAppointment(
                new Date(date),
                onGoingAppointmentNumber+1, 
                {
                    date: Timestamp.fromDate(new Date(date)),
                    number: onGoingAppointmentNumber+1,
                    userID: getUserID(),
                    dateString: getDateString(new Date(date)),
                }
            ).then((result) => {

                showSuccessToast(
                    toast,
                    'Successful!',
                    'Your Appointment successfully added!',
                );

                props.update();

            }).catch((error)=>{
                setLoadingMakeAppointment(false);

                showErrorToast(
                    toast,
                    'Oops!',
                    'Cannot make an Appointment at the moment!',
                );
            });
        }
    }

    return(
        <>
            {
                !isLoading
                ?   (
                        <VStack>
                            <Input
                                type='date' 
                                width={200}
                                value={date}
                                onChange={(date) => setDate(date.target.value)}
                            />

                            <Box height={30}/>

                            <Text>Appointment Number</Text>

                            <Heading fontSize={55}>{onGoingAppointmentNumber+1}</Heading>

                            <Box height={30}/>

                            <Text>Time</Text>

                            <Heading size='md'>{kTimeSlot[0][onGoingAppointmentNumber+1]}</Heading>

                            <Box height={30}/>

                            <Button
                                bgColor={Colors.kGreen}
                                color='white'
                                onClick={makeAppointment}
                                isLoading={isLoadingMakeAppointment}
                            >
                                Make an Appointment
                            </Button>
                        </VStack>
                    )
                :   <LoadingIndicator size={50} height={50}/>
            }
        </>
    );
}

export default NewAppointmentComponent;