import { Text, Box, Card, CardBody, Heading, Spacer, useColorMode } from "@chakra-ui/react";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { QuestionModel } from "../../../models/questionModel";
import { getQuestionDataFromID } from "../../../services/QuestionService";
import LoadingIndicator from "../../reusable-components/LoadingIndicator";

const QuestionView: React.FC = () => {

    const { id } = useParams();
    const { colorMode } = useColorMode();
    const [isLoading, setLoading] = useState(true);
    const [questionData, setQuestionData] = useState<QuestionModel>();

    useEffect(()=>{
        getQuestionData();
    },[]);

    const getQuestionData = async () => {

        await getQuestionDataFromID(id!).then((snapshot: DocumentSnapshot<DocumentData>)=>{
            setQuestionData(
                {
                    id: snapshot.id,
                    ...snapshot.data(),
                }
            );

            setLoading(false);
        });
    }

    function followLink() {
        window.open(`${questionData?.link}`);
    }
    
        return(
            <>
                {
                    isLoading
                    ? <LoadingIndicator/>
                    : (
                        <Card margin={2}>
                            <Box 
                                borderTopLeftRadius={5} 
                                borderTopRightRadius={5}
                                height={5} 
                            >
                            </Box>

                            <CardBody>
                                <Heading size='md'>{questionData?.question}</Heading>

                                <Heading size='sm'>{questionData?.answer}</Heading>

                                <Text
                                    color={colorMode === 'light' ? 'blue' : 'lightblue'}
                                    onClick={followLink}
                                >
                                    {questionData?.link}
                                </Text>

                                <Spacer height={questionData?.link !== '' ? 2 : 0}/>

                                <Text>Last Updated : {questionData?.lastUpdatedDate}</Text>

                            </CardBody>
                        </Card>
                    )
                }
            </>
        );
}

export default QuestionView;