import { Box, Button, HStack, Input, Spacer, Stack, useDisclosure, useColorModeValue } from "@chakra-ui/react";
import { collection, DocumentData, onSnapshot, query, QuerySnapshot, Timestamp } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { MdImage } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { listHeight } from "../../../constaint/Values";
import { MessageModel } from "../../../models/messageModel";
import { getUserID, isUserLogged } from "../../../services/AuthService";
import { addMessage } from "../../../services/ChatsService";
import { EncryptString } from "../../../services/EncryptionService";
import { firebaseFirestore } from "../../../services/FirebaseService";
import LoadingIndicator from "../../reusable-components/LoadingIndicator";
import NoDataLable from "../../reusable-components/NoDataLable";
import ImageUploadDialog from "./Image-Upload-Dialog";
import MessageCard from "./Message-Card";

const ChatScreen: React.FC = () => {

    const [messageID, setMessageID] = useState('');
    const [messages, setMessages] = useState<MessageModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [textInput, setTextInpurt] = useState('');

    const { isOpen: isOpenImageUpload, onOpen: onOpenImageUpload, onClose: onCloseImageUpload } = useDisclosure();
    const cancelRefImageUpload = React.useRef();

    const navigate = useNavigate();

    useEffect(() => {

        if (!isUserLogged()) {
            navigate('/login');
        }

        setMessageID(getUserID());
        getMessagesList();
    },[]);

    const getMessagesList = async () => {
        onSnapshot(
            query(
                collection(firebaseFirestore, `chats/${getUserID()}/messages`),
            ), (snapshot: QuerySnapshot<DocumentData>) => {
                setMessages(
                    snapshot.docs.map((doc)=>{
                        return {
                            id: doc.id,
                            ...doc.data(),
                        };
                    })
                );
                setIsLoading(false);
            }
        );
    }

    const sendMessage = async () => {

        const message = textInput.trim();
        setTextInpurt('');

        if(message === ''){
            return;
        }

        await addMessage(
            messageID,
            message,
            {
                content: EncryptString(message),
                type: 'text',
                from: messageID,
                dateTime: Timestamp.now(),
            }
        );

    }

    return(
        <>
            <Box
                margin={5}
                marginLeft={[2, 50, 150, 200]}
                marginRight={[2, 50, 150, 200]}
            >
                <Stack
                    overflowY='auto'
                    height={listHeight}
                    
                    border='1px'
                    borderRadius='5'
                    padding={2}
                    direction='column-reverse'
                >
                            {
                                !isLoading 
                                ? (
                                    messages && messages.length 
                                    ? (
                                        <div>
                                            {messages?.map((message: MessageModel)=>(
                                                <MessageCard key={message.id} id={messageID} messageData={message} />
                                            ))}
                                        </div>
                                    ) 
                                    : (
                                        <NoDataLable title="No Messages" />
                                    )
                                ) 
                                : (
                                    <LoadingIndicator />
                                )
                            }
                </Stack>

                <Spacer height={2}/>

                <HStack>
                    <Input
                        type='text'
                        isDisabled={isLoading}
                        value={textInput}
                        onChange={(text)=>setTextInpurt(text.target.value)}
                        borderColor={useColorModeValue('black', 'white')}
                    />

                    <Button isDisabled={isLoading} onClick={onOpenImageUpload}> <MdImage/> </Button>
                    <Button isDisabled={isLoading} onClick={sendMessage}>SEND</Button>
                </HStack>
            </Box>

                <ImageUploadDialog 
                    messageID={messageID}
                    isOpen={isOpenImageUpload} 
                    onOpen={onOpenImageUpload} 
                    onClose={onCloseImageUpload} 
                    cancelRef={cancelRefImageUpload}
                />
        </>
    );
}

export default ChatScreen;