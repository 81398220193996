import { Heading } from "@chakra-ui/react";
import React from "react";

interface NoDataLableProps{
    title: string;
    height?: number;
}

const NoDataLable: React.FC<NoDataLableProps> = (props: NoDataLableProps) => {
    return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: `${props.height ?? 50}vh`}}>
            <Heading size='lg'>{props.title}</Heading>
        </div>
    );
}

export default NoDataLable;