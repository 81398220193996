import { child, get, ref, set } from "firebase/database";
import { addDoc, collection, getDocs, limit, query, Timestamp, where } from "firebase/firestore";
import { kMaxAppointmentsCountPerDay } from "../constaint/TimeSlots";
import { getUserID } from "./AuthService";
import { sendNewAppointmentMail } from "./EmailService";
import { firebaseFirestore, firebaseRealTimeDataBase } from "./FirebaseService";

export const appointmentsCollection = collection(firebaseFirestore, 'appointments');

export const isHaveAppointment = async () => {
    var docCount = await getDocs(
        query(
            appointmentsCollection,
            where('userID', '==', getUserID()),
            where('date', '>=', Timestamp.now()),
        ), 
    ).then((value)=>value.docs.length);

    return docCount > 0;
}

export const getTodayDateString = () => {
    const date = new Date();
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const monthNumber = date.getMonth()+1;
    const month = monthNumber < 10 ? `0${monthNumber}` : monthNumber;

    return `${date.getFullYear()}-${month}-${day}`;
}

export const convertDate = (date: Date): string => {
    const month: string = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
    const day: string = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    
    return `${date.getFullYear()}${month}${day}`;
}

export const getDateString = (date: Date): string => {
    const month: string = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
    const day: string = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    
    return `${date.getFullYear()}-${month}-${day}`;
}

export const getAppointmentNumber = (date: Date): Promise<number> => {
    const appointmentsRef = ref(firebaseRealTimeDataBase);

    return get(child(appointmentsRef, `appointments/${convertDate(date)}`)).then((snapshot)=>snapshot.val() !== null ? snapshot.val() : 0);
}

export const getInitialAppointmentDate = async (date: Date): Promise<Date> => {
    if(date.getDay() === 0 || date.getDay() === 6){
        const tempDate: Date = new Date(date);
        tempDate.setDate(tempDate.getDate() + 1);
        return await getInitialAppointmentDate(tempDate);
    }

    if(await getAppointmentNumber(date) === kMaxAppointmentsCountPerDay){
        const tempDate: Date = new Date(date);
        tempDate.setDate(tempDate.getDate() + 1);
        return await getInitialAppointmentDate(tempDate);
    }

    return date;
}

export const createAppointment = async (date: Date, appointmentNumber: number, appointmentData: any) => {

    set(
        ref(
            firebaseRealTimeDataBase,
            `appointments/${convertDate(date)}`
        ), appointmentNumber
    ).then( async (value)=>{

            await addDoc(appointmentsCollection, appointmentData).then(async (value)=>{
                await sendNewAppointmentMail(getDateString(date), appointmentNumber);
            }).catch((error)=>{
                set(
                    ref(
                        firebaseRealTimeDataBase,
                        `appointments/${getDateString(date)}`
                    ), (appointmentNumber - 1)
                );
                throw error;
            });

        }
    ).catch((error)=>{
        throw error;
    });
}

export const getNextAppointmentDetails = async () => {
    return await getDocs(
        query(
            appointmentsCollection,
            where('userID', '==', getUserID()),
            where('date', '>=', Timestamp.now()),
            limit(1)
        )
    ).then((value)=>value.docs[0].data());
}