import { initializeApp } from 'firebase/app';
import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyAAHDC5Fs7jzmHfA63wabxNhYEz2n1DYGE",
    authDomain: "confacile.firebaseapp.com",
    databaseURL: "https://confacile-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "confacile",
    storageBucket: "confacile.appspot.com",
    messagingSenderId: "441941487871",
    appId: "1:441941487871:web:88569bf5cfa0e060a81cca",
    measurementId: "G-JC1BCH9GGS"
};

export const app = initializeApp(firebaseConfig);