import { SimpleGrid } from "@chakra-ui/react";
import { DocumentData, getDocs, query, QuerySnapshot, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { ConsularModel } from "../../../models/consularModel";
import { consularCollection } from "../../../services/ConsularService";
import LoadingIndicator from "../../reusable-components/LoadingIndicator";
import NoDataLable from "../../reusable-components/NoDataLable";
import SearchBar from "../../reusable-components/SearchBar";
import ConsularCard from "./Connsular-Card";

const ConsularServicesScreen: React.FC = () => {

    const [isLoading, setLoading] = useState(true);
    const [consularServices, setConsularServices] = useState<ConsularModel[]>([]);
    
    const [searchText, setSearchText] = useState('');

    // eslint-disable-next-line
    useEffect(() => getConsularServicesList(),[searchText]);

    const getConsularServicesList = () =>{
        setLoading(true);

        searchText === ''
        ?   ( 
                getDocs(
                    query(
                        consularCollection,
                    ), 
                ).then((snapshot: QuerySnapshot<DocumentData>)=>{
                    setConsularServices(
                        snapshot.docs.map((doc) => {
                            return {
                                id: doc.id,
                                ...doc.data(),
                            };
                        })
                    );
                    setLoading(false);
                })
            )
        :   (
                getDocs(
                    query(
                        consularCollection,
                        where('search', '>=', searchText),
                        where('search', '<', searchText + 'z')
                    ), 
                ).then((snapshot: QuerySnapshot<DocumentData>)=>{
                    setConsularServices(
                        snapshot.docs.map((doc) => {
                            return {
                                id: doc.id,
                                ...doc.data(),
                            };
                        })
                    );
                    setLoading(false);
                })
            )
    }

    return(
        <>

            <SearchBar setSearchText={setSearchText}/>

            {
                        !isLoading 
                        ? (
                            consularServices && consularServices.length 
                            ? (
                                <SimpleGrid columns={[1, 1, 2, 3]} >
                                    {consularServices?.map((consularService)=>(
                                        <ConsularCard key={consularService.id} consularData={consularService}/>
                                    ))}
                                </SimpleGrid>
                            ) 
                            : (
                                <NoDataLable title="No Consular Services" />
                            )
                        ) 
                        : (
                            <LoadingIndicator />
                        )
            }

        </>
    );
}

export default ConsularServicesScreen;