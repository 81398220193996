import { Box, VStack, Text, Button, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { kTimeSlot } from "../../../constaint/TimeSlots";
import { AppointmentModel } from "../../../models/appointmentModel";
import { getNextAppointmentDetails } from "../../../services/AppointmentService";
import LoadingIndicator from "../../reusable-components/LoadingIndicator";

const NextAppointmentComponent: React.FC = () => {

    const [isLoading, setLoading] = useState(true);
    const [appointmentData, setAppointmentData] = useState<AppointmentModel>();

    useEffect(()=>{
        getData();
    },[]);

    const getData = async () => {
        await getNextAppointmentDetails().then((value)=>{
            setAppointmentData(value);
            setLoading(false);
        });
    }

    return(
        <>
            {
                !isLoading
                ?   (
                        <VStack>

                            <Text>Appointment Date</Text>

                            <Heading fontSize={35}>{appointmentData?.dateString}</Heading>

                            <Box height={30}/>

                            <Text>Appointment Number</Text>

                            <Heading fontSize={55}>{appointmentData?.number}</Heading>

                            <Box height={30}/>

                            <Text>Time</Text>

                            <Heading size='lg'>{kTimeSlot[0][appointmentData?.number ?? '']}</Heading>

                        </VStack>
                    )
                :   <LoadingIndicator size={50} height={50}/>
            }
        </>
    );
}

export default NextAppointmentComponent;