import { Box, Button, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import Colors from "../../constaint/Colors";

const NotFoundScreen: React.FC = () => {

    const navigate = useNavigate();

    return (
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: `50vh`}}>
            <Box textAlign="center" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgColor={Colors.kGreen}
        backgroundClip="text">
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Page Not Found
      </Text>
      <Text color={'gray.500'} mb={6}>
        The page you're looking for does not seem to exist
      </Text>

      <Button
        colorScheme="green"
        bgColor={Colors.kGreen}
        color="white"
        variant="solid"
        onClick={()=>navigate('/')}
    >
        Go to Home
      </Button>
    </Box>
        </div>
    );
}

export default NotFoundScreen;