import { Button, Card, CardBody, Heading, HStack, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import { ContactModel } from "../../../models/contactModel";

interface ContactCardProps{contactData: ContactModel}

const ContactCard: React.FC<ContactCardProps> = (props: ContactCardProps) => {

    return(
        <>
            <Card margin={2}>

                <CardBody>

                    <HStack>
                        <Heading size='lg'>{props.contactData.title}</Heading>
                        <Spacer />

                        <Button size='lg'>
                            {
                                props.contactData.type === 'phone'
                                    ? 'Phone'
                                    : props.contactData.type === 'email'
                                        ? 'Email'
                                        : props.contactData.type === 'address'
                                            ? 'Address'
                                            : 'Fax'
                            }
                        </Button>
                    </HStack>


                    <Text minHeight={2}>{props.contactData.contact}</Text>

                </CardBody>
            </Card>

        </>
    );
}

export default ContactCard;