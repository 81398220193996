import React from "react";
import Announcements from "./Announcements";
import ButtonCards from "./Button-Cards";

const HomeScreen: React.FC = () => {
    return(
        <>
            <Announcements />
            <ButtonCards />
        </>
    );
}

export default HomeScreen;