import { collection, doc, getDocs, limit, orderBy, query, setDoc, Timestamp } from "firebase/firestore";
import { firebaseFirestore } from "./FirebaseService";

export const chatsCollection = collection(firebaseFirestore, 'chats');

export const getLastMessageFromChat = async (id: string) => {

    const chatMessagesCollection = collection(firebaseFirestore, `chats/${id}/messages`);

    const lastMessageQuery = query(
        chatMessagesCollection,
        orderBy('dateTime', 'desc'),
        limit(1)
    );

    return await getDocs(lastMessageQuery).then((snapshot)=>snapshot.docs.at(0)?.data().content);
}

export const addMessage = async (id:string, content: string, messageData: any) => {
    const dateTime = Date.now();

    const chatDoc = doc(firebaseFirestore,`chats/${id}`);
    setDoc(chatDoc, {lastSeenMessageDateTime: Timestamp.now()});

    const document = doc(firebaseFirestore, `chats/${id}/messages/${dateTime}`);
    await setDoc(document, messageData);
}