import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, HStack, Progress, Spacer } from "@chakra-ui/react";
import { Timestamp } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useState } from "react";
import Colors from "../../../constaint/Colors";
import { addMessage } from "../../../services/ChatsService";
import { firebaseStorage } from "../../../services/FirebaseService";

interface ImageUploadDialogProps {
    messageID: any;
    isOpen: any;
    onOpen: any;
    onClose: any;
    cancelRef: any;
}

const ImageUploadDialog: React.FC<ImageUploadDialogProps> = (props: ImageUploadDialogProps) => {

    const [progresspercent, setProgresspercent] = useState(0);
    const [isLoading, setLoading] = useState(false);

    const handleSubmit = (e: any) => {
      e.preventDefault()
      const file = e.target[0]?.files[0]
      if (!file) return;

      setLoading(true);

      const pieces = file.name.split(/[\s.]+/);
      const fileExtention = pieces[pieces.length - 1]

      const storageRef = ref(firebaseStorage, `chats/${props.messageID}/${Date.now()}.${fileExtention}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on("state_changed",
        (snapshot) => {
          const progress =
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgresspercent(progress);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

            await addMessage(
              props.messageID,
              'Sent an Image',
              {
                  content: downloadURL,
                  type: 'image',
                  from: props.messageID,
                  dateTime: Timestamp.now(),
              }
            ).then(()=>{

              setLoading(false);
              setProgresspercent(0);
              props.onClose();
              
            });

          });
        }
      );

    }

    return(
        <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={props.cancelRef}
        onClose={props.onClose}
        isOpen={props.isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          
          <AlertDialogHeader>Send Image</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>

              <form onSubmit={handleSubmit} className='form'>
                <HStack>
                  <input type='file'/>
                  <Button type="submit" backgroundColor={Colors.kGreen} isLoading={isLoading}>SEND</Button>
                </HStack>
              </form>

              <Spacer height={3} />
              
              <Progress hasStripe value={progresspercent} colorScheme='green'/>

          </AlertDialogBody>
          <AlertDialogFooter>

            <Button ref={props.cancelRef} onClick={props.onClose}>
              Cancel
            </Button>
  
          </AlertDialogFooter>

        </AlertDialogContent>
      </AlertDialog>
    );
}

export default ImageUploadDialog;

//<Input type='file' accept="/image/*"/>