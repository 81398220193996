export const showSuccessToast = (toast: any, title: string, description: string) => {
    toast({
        title: title,
        description: description,
        status: 'success',
        duration: 3000,
        isClosable: true,
    });
}

export const showWarningToast = (toast: any, title: string, description: string) => {
    toast({
        title: title,
        description: description,
        status: 'warning',
        duration: 3000,
        isClosable: true,
    });
}

export const showErrorToast = (toast: any, title: string, description: string) => {
    toast({
        title: title,
        description: description,
        status: 'error',
        duration: 3000,
        isClosable: true,
    });
}