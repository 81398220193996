import { SimpleGrid } from "@chakra-ui/react";
import { DocumentData, getDocs, query, QuerySnapshot, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { QuestionModel } from "../../../models/questionModel";
import { questionCollection } from "../../../services/QuestionService";
import LoadingIndicator from "../../reusable-components/LoadingIndicator";
import NoDataLable from "../../reusable-components/NoDataLable";
import SearchBar from "../../reusable-components/SearchBar";
import QuestionCard from "./Question-Card";

const QuestionsScreen: React.FC = () => {
    
    const [isLoading, setLoading] = useState(true);
    const [questions, setQuestions] = useState<QuestionModel[]>([]);

    const [searchText, setSearchText] = useState('');

    // eslint-disable-next-line 
    useEffect(() => getQuestionsList(),[searchText]);

    const getQuestionsList = () =>{
        setLoading(true);

        searchText === ''
        ?   ( 
                getDocs(
                    query(
                        questionCollection,
                    ), 
                ).then((snapshot: QuerySnapshot<DocumentData>)=>{
                    setQuestions(
                        snapshot.docs.map((doc) => {
                            return {
                                id: doc.id,
                                ...doc.data(),
                            };
                        })
                    );
                    setLoading(false);
                })
            )
        :   (
                getDocs(
                    query(
                        questionCollection,
                        where('search', '>=', searchText),
                        where('search', '<', searchText + 'z')
                    ), 
                ).then((snapshot: QuerySnapshot<DocumentData>)=>{
                    setQuestions(
                        snapshot.docs.map((doc) => {
                            return {
                                id: doc.id,
                                ...doc.data(),
                            };
                        })
                    );
                    setLoading(false);
                })
            )
    }
    
    return(
        <>
            <SearchBar setSearchText={setSearchText}/>

                    {
                        !isLoading 
                        ? (
                            questions && questions.length 
                            ? (
                                <SimpleGrid columns={[1, 1, 2, 3]} >
                                    {questions?.map((question)=>(
                                        <QuestionCard key={question.id} questionData={question}/>
                                    ))}
                                </SimpleGrid>
                            ) 
                            : (
                                <NoDataLable title="No Questions" />
                            )
                        ) 
                        : (
                            <LoadingIndicator />
                        )
                    }

        </>
    );
}

export default QuestionsScreen;