import { HStack, Input } from "@chakra-ui/react";
import React from "react";

interface SearchBarProps {setSearchText: any}

const SearchBar: React.FC<SearchBarProps> = (props: SearchBarProps) => {
    return(
        <HStack margin={2} marginLeft={[2, 100, 150, 200]} marginRight={[2, 100, 150, 200]}>

            <Input
                placeholder="SEARCH"
                onChange={(text)=>{props.setSearchText(text.target.value.toLocaleLowerCase())}}
            />

        </HStack>
    );
}

export default SearchBar;