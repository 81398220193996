import { app } from "../config/firebase";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from "firebase/storage";
import { getDatabase } from 'firebase/database';

export const firebaseFirestore = getFirestore(app);

export const firebaseAuth = getAuth(app);

export const firebaseStorage = getStorage(app);

export const firebaseRealTimeDataBase = getDatabase(app);